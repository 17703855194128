import React from 'react';
import Post from '../../components/post';


const IsItBadConsolidateDebtLoansPage = () => {
    const article = {
        id: 'fc3dd78d-75f3-5e10-a5a9-12d6ce590c8f',
        title: 'Is it Bad to Consolidate Debt',
        slug: '/loans/is-it-bad-consolidate-debt/',
        date: '2019-02-05T14:30:58.000Z',
        modified: '2021-11-03T19:36:22.000Z',
        excerpt: 'Debt consolidation is a viable, perhaps even a favorable, option when compared to bankruptcy. However, there is no option that comes without risk',
        featured_image: {
            source_url: '/media/isconsolidatingdebtbad.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 7,
        content: (
            <>
                <p>
                    Is it bad to consolidate debt? If you&rsquo;re pondering this question, you must be in over your head. It&rsquo;s okay, it happens to the best of us. In fact, according to FDIC, Americans paid over $100 billion in interest last year. With consumer debt reaching an average of $6,348 per
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=is-it-bad-consolidate-debt&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    , that can add up fast, especially if you carry 4 or 5 different
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=is-it-bad-consolidate-debt&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    in your wallet.
                </p>
                <p>
                    For many Americans, credit is like a revolving door allowing new debt to crop up before the last swipe is paid off. That&rsquo;s why it&rsquo;s also referred to as ‘
                    <em>revolving credit</em>
                    ‘. It&rsquo;s an allure that too often leaves us with unmanageable debt before we realize how toxic our spending habits might be. It&rsquo;s a costly lesson to learn, and often the situation becomes further compounded by a lack of favorable options.
                </p>
                <p><em><strong>Cue, the practice of debt consolidation.</strong></em></p>
                <p>Debt consolidation can mean a couple of different things. For the purposes of this article it is a process that occurs when someone negotiates on your behalf to reduce the total cost of your debt, potentially reduce the interest, and renegotiate the time that you have to pay it down. It offers a single installment plan that&rsquo;s easier to manage than the barrage of high interest payments that make up your current reality, and doesn&rsquo;t require the consumer to take out a loan. These negotiations usually occur at the hands of a contracted agency, but consumers can also partner with an attorney or other financial representative working on their behalf. </p>
                <p>
                    If you&rsquo;re wondering if it&rsquo;s bad to consolidate debt, you&rsquo;ve probably heard mixed reviews and horror stories that can be confusing, at best. With many people, like creditors, coming after a chunk of your hard earned cash, you may feel vulnerable to scam artists and want to know you&rsquo;re making the best decision. The truth is sometimes debt consolidation is the only decision outside of bankruptcy. In the following article, we&rsquo;re going to talk about debt consolidation (and when it makes sense for the average indebted consumer) and highlight some
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-repayment-plan/">repayment considerations</a>
                    {' '}
                    that might help.
                </p>
                <h1>Debt Consolidation, Attorney, or Bankruptcy?</h1>
                <p>
                    If you&rsquo;re on the brink of making the decision to consolidate debt whether it be from
                    {' '}
                    <a href="https://www.debtconsolidation.com/medical-bills/">mounting medical bills</a>
                    {' '}
                    or otherwise, it&rsquo;s likely that financing options with more favorable terms aren&rsquo;t available to you.
                    {' '}
                    <em><strong>Why is that?</strong></em>
                    {' '}
                    In order to secure a
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=is-it-bad-consolidate-debt&amp;sub2=loan" rel="noopener noreferrer" target="_blank">loan</a>
                    {' '}
                    of any kind, you have to have decent credit. If you don&rsquo;t, it can be very challenging to
                    {' '}
                    <a href="https://www.debtconsolidation.com/loans/debt-consolidation-loan-bad-credit/">get an installment loan</a>
                    {' '}
                    to chip away at debt.
                    {' '}
                </p>
                <p>For those who have reviewed their financing options and come up with zilch, debt consolidation is a viable, perhaps even a favorable, option when compared to bankruptcy or hiring an attorney. Here&rsquo;s why:</p>
                <h3>Hiring an Attorney</h3>
                <p>Attorneys are expensive. If you&rsquo;ve ever had to deal with any legal issues, this may come as no surprise. Like debt consolidators, pricey attorneys can&rsquo;t make claim to reduce your debt or interest by a certain guaranteed amount. However, unlike debt consolidators, attorneys usually charge fees upfront to secure their services. </p>
                <p>Debt consolidators are forbidden by law from collecting upfront fees before a settlement has been reached. Legislation designed to protect consumers from scams and fraud only applies to licensed debt consolidation companies, and not to those who otherwise work in the legal side of financial services.</p>
                <p>
                    <em><strong>What does that mean for you?</strong></em>
                    {' '}
                    Partnering with an attorney is one good way to potentially reduce your debt, but it&rsquo;s going to cost you&mdash;
                    <em><strong>big time! </strong></em>
                    When you partner with a licensed debt consolidation firm, you won&rsquo;t pay any fees until a settlement is reached and agreed upon.
                </p>
                <h3>Filing for Bankruptcy</h3>
                <p>
                    There are three types of bankruptcy, chapter 7, chapter 11, and chapter 13, but for consumers we are
                    {' '}
                    <a href="/bankruptcy/">most often speaking about chapter 7 or chapter 13</a>
                    . In chapter 7 bankruptcy, some of your assets are liquidated to pay off a portion of the debt and the rest is forgiven. That may sound too good to be true, and for some, it is. In order to qualify for Chapter 7, you have to prove your household income, after essential bills are paid, is below an average determined by your state government.
                    {' '}
                </p>
                <p>Chapter 13 is a little easier to qualify. During a Chapter 13 bankruptcy you agree to reduce or eliminate your debt over a certain number of years by making an installment payment that&rsquo;s affordable each month. After the strict payment plan ends, any remaining qualifying debt is forgiven. All bankruptcy halts collection efforts.</p>
                <p>
                    <em><strong>What does that mean for you?</strong></em>
                    {' '}
                    These are very viable options for anyone with a lot of debt, but they aren&rsquo;t without their shortfalls. Filing for bankruptcy hurts your credit in a way that is often severe and hard to swallow. Most consumers stick with the rule of thumb that a bankruptcy will impact your credit for a decade. In some states, a bankruptcy from 7 or 8 years ago can prevent you from getting a conventional loan to purchase a home or other asset. While bankruptcy allows you to retain your personal possessions, depending on your situation and which chapter you file, you may have to give up big assets in a settlement to pay down debt, like your car or your house.
                </p>
                <p>
                    For these reasons, consumers don&rsquo;t approach bankruptcy with reckless abandon. Instead, bankruptcy attorneys and experts suggest the time to consider it is in the event of major
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=49&amp;sub1=is-it-bad-consolidate-debt&amp;sub2=life" rel="noopener noreferrer" target="_blank">life</a>
                    {' '}
                    changes like losing income to illness or accident. If there&rsquo;s another way, filing for bankruptcy may not be in best interest.
                </p>
                <h3>Debt Consolidation</h3>
                <p>When a debt consolidation takes place, you, the consumer, have entered into a contract with a debt consolidation agency. The contract is important, because it allows debt consolidation companies to comply with national laws so that you don&rsquo;t have to put any money down upfront. </p>
                <p>While the ink is still drying, debt consolidation agents work on your behalf to negotiate your qualifying debt. One drawback to this approach is that your debt has to qualify. Usually, this means credit debt has to be large enough for debt consolidation companies to work with you.</p>
                <p>Once a settlement has been reached between the debt consolidation company and your creditors, the debt consolidators will be responsible for paying your creditors and you will pay the debt consolidators, directly. This is the part of the process that sometimes sets off alarm bells. Rest assured the contract should protect you from mismanagement of your money. It&rsquo;s vitally important to read the fine print and equally so to work with a licensed debt consolidation agency so you know they comply with state and federal laws.</p>
                <p>
                    <em><strong>What does that mean for you?</strong></em>
                    {' '}
                    The result is usually a decrease in overall debt, a potential decrease in overall interest, and a manageable monthly payment to a debt consolidation company over the course of 3-5 years. The company is often a for-profit entity
                    {' '}
                    <em>(although non-profit credit counseling does exist)</em>
                    , so they may make money on your reduced monthly payment in a win-win scenario that gets you out of debt without as much lasting
                    {' '}
                    <a href="https://www.debtconsolidation.com/loans/do-consolidation-loans-hurt-credit-score/">damage to your credit score</a>
                    .
                </p>
                <h2 id="debt-consolidation-risks">Debt Consolidation Risks</h2>
                <p>That&rsquo;s a debt consolidation service in a nutshell. If you&rsquo;ve made it to this point, hopefully you have a clearer idea about what exactly your options are as an indebted consumer. That said, there is no option that comes without risk. </p>
                <p>
                    Let&rsquo;s sit with that for a moment:
                    {' '}
                    <em><strong>once you&rsquo;re in deep with creditors, there&rsquo;s always risk no matter what you decide is the best course of action for you and your family. </strong></em>
                </p>
                <p>With bankruptcy, you risk putting a harmful scar on your credit report and potentially losing assets. With attorneys, you risk paying steep consultation fees and a retainer, only to have the negotiation go south and the resolution be lackluster or nonexistent.</p>
                <p>When it comes to debt consolidation there&rsquo;s a unique risk that sometimes rears its head depending on the design of the particular program you enter. Some debt consolidation companies may ask you not to speak with creditors or make payments during the negotiation process, and depending on how long that takes, it can end very badly for the consumer who follows this advice.</p>
                <p>At DebtConsolidation.com, we don&rsquo;t recommend ignoring your creditors or ceasing payments prior to reaching a debt settlement. Quite frankly, that&rsquo;s how lawsuits happen. When your creditors need to talk to you, the best thing to do is answer the phone and let them know what&rsquo;s going on and what solutions you are trying. </p>
                <p>Failure to do so, and to pay at least the minimum, can land you with a summons to appear in court. That will surely make you an unhappy debt consolidation customer. On the other hand, debt consolidation services that are doing things the right way likely won&rsquo;t ask you to stop all communication with creditors. Remember, the decision to do so is yours, alone. As with any big financial decision, proceed, but do it cautiously and armed with the knowledge to protect yourself from risk and reduce your debt.</p>
                <h2 id="consolidating-debt-is-it-bad">Consolidating Debt: Is it Bad?</h2>
                <p>
                    The answer is:
                    {' '}
                    <em><strong>no</strong></em>
                    . Debt consolidation is a service that helps many Americans get out of debt each year. It can give you the opportunity to have a better financial future without any upfront fees and without too much long-term damage to your credit report. Sometimes, debt consolidation is the only good option.
                </p>
                <p>That&rsquo;s especially true for people who have exhausted financing resources and who don&rsquo;t have the money to pay steep attorney fees. However, there&rsquo;s always risk involved when it comes to credit cards, and resolving debt is no different. It&rsquo;s important to understand, going into the agreement with a debt consolidator, what the terms are and what&rsquo;s expected of you, and to feel empowered to advocate for yourself, your credit score and your path to financial wellness.</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default IsItBadConsolidateDebtLoansPage;
